import { CloseOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import DateRangeOutlined from '@mui/icons-material/DateRangeOutlined';
import { useIntl } from 'react-intl';
import { RawHtmlContent, Translate } from '../../i18n/translate';
import { formatDate } from '../../utils/helper';
import { useState } from 'react';
import './status.scss';

const Status = ({
  currentStatusIndex,
  statusObj,
  totalStatus,
  success,
  nextStatusObj,
  variables,
  isHistorical,
}) => {
  const [showDetails, setShowDetails] = useState(!isHistorical);
  const intl = useIntl();

  const declineReasonHasI18n = () => {
    if (variables.dealDeclinedReturnCodeKey && variables.dealDeclinedReturnMessageKey) {
      return (
        intl.formatMessage({ id: variables.dealDeclinedReturnCodeKey }) !=
          variables.dealDeclinedReturnCodeKey &&
        intl.formatMessage({ id: variables.dealDeclinedReturnMessageKey }) !=
          variables.dealDeclinedReturnMessageKey
      );
    }
    return false;
  };

  return (
    <>
      <div className='flex md:justify-center gap-0 md:gap-5'>
        {/* Date */}
        <div className='w-[16%] hidden md:block mt-[-3px]'>
          {statusObj.actionDate && (
            <>
              <DateRangeOutlined />
              <span className='ml-1 text-14-medium'>{formatDate(statusObj.actionDate)}</span>{' '}
            </>
          )}
        </div>

        {/* Details */}
        <div
          className={`w-[100%] sm:w-[60%] border-l-gray3 border-l-2 ${
            currentStatusIndex !== totalStatus - 1 ? '' : 'border-l-transparent'
          }  relative ${
            nextStatusObj?.actionDate
              ? !success && currentStatusIndex + 1 === totalStatus - 1
                ? 'border-dashed'
                : 'border-l-primary'
              : ''
          }`}
        >
          {/* if success is false and last index, show red cross icon */}
          {currentStatusIndex === totalStatus - 1 && !success ? (
            <div className='w-[16px] h-[16px] rounded-full bg-error2 absolute left-[-9px] top-0 flex items-center justify-center'>
              <CloseOutlined className='text-[12px] text-white' />
            </div>
          ) : (
            <div
              className={`${
                !statusObj.actionDate
                  ? 'w-[8px] h-[8px] bg-gray6 left-[-5px]'
                  : 'w-[16px] h-[16px] bg-primary left-[-9px]'
              } rounded-full absolute`}
            ></div>
          )}

          <div
            className={`pb-12 pl-6 md:pl-14 ${statusObj.actionDate ? 'mt-[-8px]' : 'mt-[-12px]'}`}
          >
            <div className='block md:hidden mb-3'>
              {statusObj.actionDate && (
                <>
                  <DateRangeOutlined />
                  <span className='ml-1 text-14-medium'>
                    {formatDate(statusObj.actionDate)}
                  </span>{' '}
                </>
              )}
            </div>

            <div className='flex gap-2 items-start md:items-center flex-col md:flex-row'>
              <div
                className={`p-[5px] text-sm-bold sm:text-14-bold text-white inline-block ${
                  statusObj.actionDate ? 'bg-midnight' : 'bg-disabled'
                }`}
              >
                <RawHtmlContent
                  htmlContent={statusObj.transactionStatusHeaderKey}
                  translateValues={variables}
                />
              </div>
              {isHistorical && (
                <div
                  onClick={() => setShowDetails(!showDetails)}
                  className='border-b border-midnight text-midnight text-sm flex items-center justify-center h-[20px] cursor-pointer'
                >
                  <span className='text-midnight'>
                    {showDetails ? (
                      <Translate value='PT_HIDE_DETAILS' />
                    ) : (
                      <Translate value='PT_SHOW_DETAILS' />
                    )}
                  </span>
                  <span>
                    {!showDetails ? (
                      <KeyboardArrowDown className='text-16' />
                    ) : (
                      <KeyboardArrowUp className='text-16' />
                    )}
                  </span>
                </div>
              )}
            </div>

            <p
              className={`text-sm mt-3 ${
                statusObj.actionDate ? 'text-black' : 'text-disabled hidden'
              } accordion-content ${showDetails ? 'open' : ''}`}
            >
              <RawHtmlContent
                htmlContent={statusObj.transactionStatusDescKey}
                translateValues={variables}
              />
            </p>
            {declineReasonHasI18n() && (
              <>
                <div className='mt-[10px] text-sm-bold text-midnight decline-reason'>
                  <span>
                    <Translate value='WP_DECLINE_REASON' />
                  </span>
                  <Translate value={variables.dealDeclinedReturnCodeKey} />
                </div>
                <div className='mt-[5px] text-sm decline-reason'>
                  <RawHtmlContent
                    htmlContent={variables.dealDeclinedReturnMessageKey}
                    translateValues={variables}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Status;
