import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { subscribeNotification } from '../../apis';
import { getTranslatedValue, Translate } from '../../i18n/translate';
import { selectDialCodes } from '../../store/slices/refdata/refdataSlice';

const SmsUpdate = ({ pledgeId, payerCountryCode }) => {
  const [dialCode, selectDialCode] = useState(null);
  const [error, setError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [sortedDialCodes, setSortDialCodes] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const dialCodes = useSelector(selectDialCodes);
  const intl = useIntl();

  useEffect(() => {
    let _sortedDialCodes = [...dialCodes].sort((a, b) => {
      let aTranslated = intl.formatMessage({ id: a.countryCode });
      let bTranslated = intl.formatMessage({ id: b.countryCode });
      return aTranslated.localeCompare(bTranslated);
    });
    _sortedDialCodes = _sortedDialCodes.filter((c) => c.smsEnabled);
    setSortDialCodes(_sortedDialCodes);

    //select country from payerCountryCode initialy
    if (payerCountryCode) {
      let dialC = _sortedDialCodes.find((e) => e.countryCode === payerCountryCode);
      dialC && selectDialCode(dialC);
    }
  }, [dialCodes]);

  const handleChange = () => {
    // setDefaultChecked(true);
  };

  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const submit = async () => {
    setLoading(true);
    setError('');
    setSuccess(false);
    let response = await subscribeNotification(phoneNumber, dialCode.countryCode, pledgeId);
    setLoading(false);
    if (response.success) {
      response = response.data;
      if (response.validationResult.success) {
        setSuccess(true);
      } else {
        setError(response.validationResult.errors[0].errorCode);
      }
    }
  };

  if (success) {
    return (
      <div className='flex-col sm:flex-row flex items-center justify-center gap-4'>
        <p className='text-14'>
          <span className='mr-2'>
            <CheckCircleOutlineIcon />
          </span>
          <Translate value='PT_SMS_REG_SUCCESS' />
        </p>
        <Button
          className='text-sm-bold h-[35px] px-[15px] rounded-full'
          variant='contained'
          disableElevation
          onClick={() => {
            selectDialCode('');
            setPhoneNumber('');
            setSuccess(false);
          }}
          // disabled={!phoneNumber}
        >
          <Translate value='PT_SMS_REG_NEW' />
        </Button>
      </div>
    );
  }

  return (
    <>
      <p className='max-w-[90%] m-auto text-sm md:text-14 text-center text-midnight'>
        <Translate value='PT_SMS_NOTIFY' />
      </p>
      <div className='flex justify-center'>
        <div className='w-full sm:w-[600px]'>
          <FormControl fullWidth>
            <div className='flex gap-1 items-center mt-3 flex-col md:flex-row'>
              <div className='flex grow w-full md:w-auto'>
                <Select
                  labelId='demo-controlled-open-select-label'
                  id='demo-controlled-open-select'
                  className='rounded-tr-none rounded-br-none min-w-[123px]'
                  value={dialCode}
                  color='secondary'
                  MenuProps={{
                    disablePortal: true,
                  }}
                  sx={{
                    height: '45px',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black !important',
                      borderWidth: '1px !important',
                    },
                    '& .MuiOutlinedInput-root': {
                      height: '45px',
                      borderColor: 'black !important',

                      '& .MuiSelect-select': {
                        height: '45px',
                        display: 'flex',
                        alignItems: 'center',
                      },
                    },
                  }}
                  onBlur={handleChange}
                  onChange={(e) => selectDialCode(e.target.value)}
                  renderValue={(dialCode) => {
                    const flagClassName = `gpfs-flag ${dialCode.countryCode.toLowerCase()}`;
                    return (
                      <div className=''>
                        <i key={dialCode.countryCode} className={flagClassName}></i>
                        <span className='ml-3 align-super'>+{dialCode.dialingCode}</span>
                      </div>
                    );
                  }}
                >
                  {sortedDialCodes.map((option, index) => {
                    const flagClassName = `gpfs-flag ${option.countryCode.toLowerCase()}`;
                    return (
                      <MenuItem key={index} value={option}>
                        <i key={option.countryCode} className={flagClassName}></i>
                        <Translate value={option.countryCode} />
                      </MenuItem>
                    );
                  })}
                </Select>
                <TextField
                  id='filled-start-adornment'
                  className='w-full tel-input'
                  variant='outlined'
                  error={error}
                  placeholder={getTranslatedValue('PT_PHONE_ENTER')}
                  value={phoneNumber}
                  onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                      e.preventDefault();
                    }
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: '45px',
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderColor: 'black',
                      '& fieldset': {
                        borderColor: 'black', // Always have black border
                        borderLeft: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: 'black', // Maintain black border on hover
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black', // No focus highlight, always black
                        borderWidth: '1px',
                      },
                    },
                  }}
                  onChange={handlePhoneNumber}
                />
              </div>
              <Button
                className='text-sm-bold h-[35px] px-[34px] rounded-full w-full mt-4 md:w-auto md:mt-0'
                variant='contained'
                disableElevation
                onClick={() => submit()}
                disabled={!phoneNumber}
              >
                <Translate value='PT_SUBMIT' />
                {loading && <CircularProgress size={12} color='secondary' className='ml-2' />}
              </Button>
            </div>
          </FormControl>
          {/* {success && (
          <FormHelperText className='text-success text-center'>
            <Translate value={'JS_SMS_MESSAGE_SUCCESS'} />
          </FormHelperText>
        )} */}
          {error && (
            <FormHelperText className='text-error text-center'>
              <Translate value={error} />
            </FormHelperText>
          )}
        </div>
      </div>
    </>
  );
};

export default SmsUpdate;
