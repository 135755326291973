import { CircularProgress, Popper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { mountPoint } from '../../utils/helper';

export const InstitutionSelect = ({
  id = 'institution-select',
  institutions,
  setInstitution,
  disabled = false,
  value,
  error,
  dataFetching,
}) => {
  const [filteredInstitutions, setFilteredInstitutions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState(null);

  useEffect(() => {
    if (institutions) {
      let _institutions = institutions.map((inst) => {
        const obj = { id: inst.id, name: inst.name };
        if (inst.id === value) {
          setSelectedInstitution(obj);
          setInstitution(inst.id);
        }
        return obj;
      });
      _institutions = _institutions.sort((i1, i2) => (i1.name > i2.name ? 1 : -1));
      setFilteredInstitutions(_institutions);
    }

    if (!value) {
      setSelectedInstitution(null);
    }
  }, [institutions, value]);

  const filterOptions = (options, { inputValue }) => {
    const inputValueLowerCase = inputValue.toLowerCase();
    const list = options.filter((option) => {
      const splittedWords = option.name.split(' ');
      return splittedWords.find((w) => w.toLowerCase().startsWith(inputValueLowerCase));
    });
    return list.length
      ? list
      : options.filter((option) => option.name.toLowerCase().startsWith(inputValueLowerCase));
  };

  return (
    <Autocomplete
      id={id}
      options={filteredInstitutions}
      autoHighlight
      getOptionLabel={(option) => option.name}
      value={selectedInstitution}
      disabled={disabled}
      filterOptions={filterOptions}
      PopperComponent={(props) => <Popper {...props} container={mountPoint()} />}
      onChange={(_, v) => {
        if (v) {
          setSelectedInstitution(v);
          setInstitution(v.id);
        } else {
          setSelectedInstitution(null);
          setInstitution(null);
        }
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.name}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {dataFetching && <CircularProgress color='inherit' size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
            autoComplete: 'new-password',
            placeholder: 'Select Institution',
          }}
        />
      )}
    />
  );
};
