import { createSlice } from '@reduxjs/toolkit';

const mainSlice = createSlice({
  name: 'main',
  initialState: {
    token: '',
    error: '',
    isLoading: false,
    trackingResult: null,
    locale: 'en_GB',
    fallBackUrl: '',
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setTrackingResult: (state, action) => {
      state.trackingResult = action.payload;
    },
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    setFallBackUrl: (state, action) => {
      state.fallbackUrl = action.payload;
    },
  },
});

export const { setToken, setError, setLoading, setTrackingResult, setLocale, setFallBackUrl } =
  mainSlice.actions;
export const selectToken = (state) => state.main.token;
export const selectLoading = (state) => state.main.isLoading;
export const selectTrackingResult = (state) => state.main.trackingResult;
export const selectLocale = (state) => state.main.locale;
export const selectFallbackUrl = (state) => state.main.fallbackUrl;
export default mainSlice.reducer;
