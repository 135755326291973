export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const monthName = date.toLocaleString('en-US', { month: 'short' });
  const day = date.toLocaleString('en-US', { day: '2-digit' });
  const year = date.toLocaleString('en-US', { year: 'numeric' });
  return `${monthName}-${day}-${year}`;
};

export const mountPoint = () => {
  const shadowHost = document.querySelector('#convera-payment-tracking');
  if (!shadowHost) {
    return document.body;
  }
  const shadowRoot = shadowHost.shadowRoot;
  const _mountPoint = shadowRoot.querySelector('.c-mount-point');
  return _mountPoint;
};

export const openTrackingOnFallBackUrl = (fallBackUrl, reference, type) => {
  window.location.href = `${fallBackUrl}?reference=${reference}&type=${type}`;
};
